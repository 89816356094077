import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-mini';

export const shapeResponse = (responseData) => {
	if (!get(responseData, 'attributes', null)) return null;

	const pageId = responseData.id;
	const data = responseData.attributes;
	data.pageId = pageId;

	data.DynamicZone.forEach((component) => {
		if (get(component, 'titleImages.data', null)) {
			component.titleImages = component.titleImages.data.map((image) => ({
				url: get(image, 'attributes.url', ''),
				alternativeText: get(image, 'attributes.alternativeText', ''),
			}));
		}
		if (get(component, 'titleImage.data', null)) {
			component.titleImage = {
				url: get(component, 'titleImage.data.attributes.url', ''),
				alternativeText: get(
					component,
					'titleImage.data.attributes.alternativeText',
					''
				),
			};
		}
		if (get(component, 'bannerBackground.data', null)) {
			component.bannerBackground = {
				url: get(component, 'bannerBackground.data.attributes.url', ''),
				alternativeText: get(
					component,
					'bannerBackground.data.attributes.alternativeText',
					''
				),
			};
		}
		if (get(component, 'cardList', null)) {
			component.cardList = component.cardList.map((card) => {
				card.image = {
					url: get(card.image, 'data.attributes.url', ''),
					alternativeText: get(
						card.image,
						'data.attributes.alternativeText',
						''
					),
				};

				return card;
			});
		}
		if (get(component, 'form', null)) {
			component.form = get(component, 'form.data.attributes', null);
		}
		if (get(component, 'titleIcon.data', null)) {
			component.titleIcon = {
				url: get(component, 'titleIcon.data.attributes.url', ''),
				alternativeText: get(
					component,
					'titleIcon.data.attributes.alternativeText',
					''
				),
			};
		}
		if (get(component, 'perk', null)) {
			component.perk = component.perk.map((perk) => ({
				...perk,
				perkImage: {
					url: get(perk, 'perkImage.data.attributes.url', ''),
					alternativeText: get(
						perk,
						'perkImage.data.attributes.alternativeText',
						''
					),
				},
			}));
		}
		if (get(component, 'locations.data', null)) {
			component.locations = component.locations.data.map((location) =>
				get(location, 'attributes')
			);
		}

		if (get(component, 'categories.data', null)) {
			component.categories = component.categories.data.map((category) =>
				get(category, 'attributes')
			);
		}

		if (get(component, 'tags.data', null)) {
			component.tags = component.tags.data.map((tag) => get(tag, 'attributes'));
		}

		if (get(component, 'buttonActions', null)) {
			component.buttonActions = component.buttonActions.data.map((action) =>
				get(action, 'attributes')
			);
		}

		if (!data.faqs && get(component, '__component') === 'shared.faq-section') {
			data.faqs = component.cardList;
		}

		if (get(component, '__component') === 'shared.plan-comparison-section') {
			component.cardList.forEach((planGroup) => {
				planGroup.offeredPlans = planGroup.offeredPlans.data.map(
					({ attributes }) => attributes
				);
			});
		}

		if (
			get(component, '__component') === 'event-page.event-group-banner-section'
		) {
			component.event = get(component, 'event.data', []).reduce(
				(events, { attributes }) => {
					if (getUpcomingEventData(attributes)) {
						const form = get(attributes, 'form.data.attributes', null);
						events.push({
							...attributes,
							titleImage: {
								url: get(attributes, 'image.data.attributes.url', ''),
								alternativeText:
									get(attributes, 'image.data.attributes.alternativeText') ||
									attributes.title,
							},
							timer: { dateTime: attributes.endTime },
							ctaButton: {
								label: 'Register',
								openFormModal: Boolean(form),
								link: form ? null : `/events-webinars/${attributes.slug}`,
								shadow: false,
							},
							form,
							authUserForm: get(
								attributes,
								'authUserForm.data.attributes',
								null
							),
						});
					}

					return events;
				},
				[]
			);
		}
	});

	return data;
};

const getUpcomingEventData = (eventData, nextUpcomingEvent) => {
	if (!eventData && !nextUpcomingEvent) return null;

	let useNextUpcomingEvent = true;

	if (eventData) {
		useNextUpcomingEvent = eventData.endTime
			? moment().isAfter(moment(eventData.endTime))
			: false;
	}

	return useNextUpcomingEvent ? nextUpcomingEvent : eventData;
};

export const getEventDetailBannerSectionProps = (data) => ({
	id: data.id,
	__component: 'event-page.event-detail-page-banner-section',
	title: data.title,
	highlightTitle: data.highlightTitle,
	description: data.description,
	titleFontSize: data.titleFontSize,
	mobileDescription: data.mobileDescription,
	timer: { dateTime: data.endTime },
	endTime: data.endTime,
	dateTime: data.dateTime,
	titleImage: {
		url: get(data, 'image.data.attributes.url', ''),
		alternativeText: get(data, 'image.data.attributes.alternativeText', ''),
	},
});

export const getUpcomingEventSectionProps = (
	eventBannerSectionData,
	nextUpcomingEvent
) => {
	const eventData = getUpcomingEventData(
		get(eventBannerSectionData, 'event.data.attributes'),
		nextUpcomingEvent
	);

	if (!eventData) return null;

	return {
		...eventData,
		id: eventBannerSectionData.id,
		__component: eventBannerSectionData.__component,
		titleFontSize: eventBannerSectionData.titleFontSize,
		timer: { dateTime: eventData.endTime },
		ctaButton: eventBannerSectionData.ctaButton,
		form: get(eventData, 'form.data.attributes'),
		authUserForm: get(eventData, 'authUserForm.data.attributes'),
		titleImage: {
			url: get(eventData, 'image.data.attributes.url', ''),
			alternativeText: get(
				eventData,
				'image.data.attributes.alternativeText',
				''
			),
		},
	};
};

export const needToFetchRelatedData = (
	componentArray,
	targetComponentIndex,
	targetKey
) =>
	isEmpty(componentArray) ||
	isEmpty(componentArray[targetComponentIndex]) ||
	isEmpty(componentArray[targetComponentIndex][targetKey])
		? true
		: false;

export const isValidIndex = (index) =>
	index !== undefined && index !== null && typeof index === 'number';

export const setRelatedBlogsSection = (data, sectionListKey, blogs) => {
	if (isEmpty(blogs)) return;

	if (isValidIndex(data.relatedBlogSectionIndex)) {
		data[sectionListKey][data.relatedBlogSectionIndex].blogs = blogs;
		return;
	}

	data[sectionListKey].push({
		id: 1,
		__component: 'blog-page.related-blogs',
		title: 'Related Blogs',
		blogs,
	});
};

export const setRelatedEventsSection = (data, sectionListKey, events) => {
	if (isEmpty(events)) return;

	if (isValidIndex(data.relatedEventSectionIndex)) {
		data[sectionListKey][data.relatedEventSectionIndex].events = events;
		return;
	}

	data[sectionListKey].push({
		id: 1,
		__component: 'event-page.related-events',
		title: 'Related Events',
		events,
	});
};

export const removeRelatedData = (data, sectionListKey) => {
	const { relatedBlogSectionIndex, relatedEventSectionIndex } = data;

	if (
		isValidIndex(relatedBlogSectionIndex) &&
		isValidIndex(relatedEventSectionIndex)
	) {
		if (relatedBlogSectionIndex > relatedEventSectionIndex) {
			data[sectionListKey].splice(relatedEventSectionIndex, 1);
			data[sectionListKey].splice(relatedBlogSectionIndex - 1, 1);
		} else {
			data[sectionListKey].splice(relatedBlogSectionIndex, 1);
			data[sectionListKey].splice(relatedEventSectionIndex - 1, 1);
		}
		return;
	}

	if (isValidIndex(relatedBlogSectionIndex)) {
		data[sectionListKey].splice(relatedBlogSectionIndex, 1);
	}

	if (isValidIndex(relatedEventSectionIndex)) {
		data[sectionListKey].splice(relatedEventSectionIndex, 1);
	}
};
