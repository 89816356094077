//actions
import { getCMSData } from '@/api';

// utils
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { populateSectionList } from './page';

export const blogPageListAction = async ({
	page = 1,
	pageSize = 20,
	tagFilterList = [],
	searchText,
	countries = [],
	tags = [],
	categories = [],
	exclude,
	searchQueryValues = {},
}) => {
	const andFilterArray = [];
	if (exclude) {
		andFilterArray.push({ slug: { $ne: exclude } });
	}
	if (countries.length > 0) {
		andFilterArray.push({ locations: { name: { $in: countries } } });
	}
	if (tags.length > 0) {
		andFilterArray.push({ tags: { name: { $in: tags } } });
	}
	if (categories.length > 0) {
		andFilterArray.push({ categories: { name: { $in: categories } } });
	}

	const orArray = [];
	if (!isEmpty(searchQueryValues.category)) {
		orArray.push(
			{ tags: { name: { $eqi: searchQueryValues.category } } },
			{ categories: { name: { $eqi: searchQueryValues.category } } }
		);
	}
	if (!isEmpty(searchQueryValues.countries)) {
		orArray.push({
			locations: {
				name: {
					$in:
						typeof searchQueryValues.countries === 'string'
							? searchQueryValues.countries.split(',')
							: searchQueryValues.countries,
				},
			},
		});
	}
	if (orArray.length) {
		andFilterArray.push({ $or: orArray });
	}

	if (searchText) {
		andFilterArray.push({
			$or: [
				{ title: { $containsi: searchText } },
				{ categories: { name: { $containsi: searchText } } },
			],
		});
	}
	if (tagFilterList.length > 0) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $in: tagFilterList } } },
				{ categories: { name: { $in: tagFilterList } } },
				{ locations: { name: { $in: tagFilterList } } },
			],
		});
	}
	let result = await getCMSData('blog-pages', {
		filters: { $and: andFilterArray },
		populate: {
			image: '*',
			categories: { fields: ['name', 'slug'] },
		},
		sort: ['publishedAt:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const blogPageAction = async (slug) => {
	let result = await getCMSData('blog-pages', {
		filters: { slug: { $eq: slug } },
		populate: 'deep',
		shapeListResponse: 'true',
	});

	const data = get(result, 'data.data[0]', null);
	if (data) {
		data.countries = data.locations;
		await populateSectionList(data, 'otherPageSections');
	}

	return data;
};
