import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-mini';

// api
import { getCMSData } from '@/api';

// utils
import { populateSectionList } from './page';
import { removeRelatedData } from './utils';

const PAN = 'Pan';

export const eventListSitemapAction = async (page = 1, pageSize = 100) => {
	const result = await getCMSData('event-pages', {
		filters: {
			$and: [
				{ showOnListingPage: true },
				{ $or: [{ noIndex: false }, { noIndex: { $null: true } }] },
			],
		},
		sort: ['dateTime:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const eventPageListAction = async ({
	page = 1,
	pageSize = 20,
	tagFilterList = [],
	dayFilter,
	pastEvent,
	city,
	countries = [],
	tags = [],
	categories = [],
	exclude,
	searchQueryValues = {},
	eventType,
}) => {
	const andFilterArray = [{ showOnListingPage: true }];
	if (exclude) {
		andFilterArray.push({ slug: { $ne: exclude } });
	}
	if (countries.length > 0) {
		andFilterArray.push({ countries: { name: { $in: countries } } });
	}
	if (tags.length > 0) {
		andFilterArray.push({ tags: { name: { $in: tags } } });
	}
	if (categories.length > 0) {
		andFilterArray.push({ categories: { name: { $in: categories } } });
	}
	if (city && city !== PAN) {
		andFilterArray.push({ city: { name: { $eq: city } } });
	}
	if (eventType) {
		andFilterArray.push({ eventType: { $eq: eventType } });
	}

	if (!isEmpty(searchQueryValues.utm_category)) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $eqi: searchQueryValues.utm_category } } },
				{ categories: { name: { $eqi: searchQueryValues.utm_category } } },
			],
		});
	}

	if (!isEmpty(searchQueryValues.countries)) {
		andFilterArray.push({
			countries: {
				name: {
					$in:
						typeof searchQueryValues.countries === 'string'
							? searchQueryValues.countries.split(',')
							: searchQueryValues.countries,
				},
			},
		});
	}
	if (
		!isEmpty(searchQueryValues.cities) &&
		!searchQueryValues.cities.includes(PAN)
	) {
		andFilterArray.push({
			$or: [
				{
					city: {
						name: {
							$in:
								typeof searchQueryValues.cities === 'string'
									? searchQueryValues.cities.split(',')
									: searchQueryValues.cities,
						},
					},
				},
				{ city: { name: { $eqi: PAN } } },
				{ eventType: { $eq: 'online' } },
			],
		});
	}

	if (dayFilter || !pastEvent) {
		const date = moment();
		let dateTimeFilter = { endTime: {} };
		if (!pastEvent) {
			dateTimeFilter['endTime']['$gt'] = date.toISOString();
		}
		switch (dayFilter) {
			case 'week':
				dateTimeFilter['endTime']['$lt'] = date.endOf('week').toISOString();
				break;
			case 'month':
				dateTimeFilter['endTime']['$lt'] = date.endOf('month').toISOString();
				break;
		}
		andFilterArray.push(dateTimeFilter);
	}
	if (tagFilterList.length > 0) {
		andFilterArray.push({
			$or: [
				{ tags: { name: { $in: tagFilterList } } },
				{ categories: { name: { $in: tagFilterList } } },
				{ location: { name: { $in: tagFilterList } } },
			],
		});
	}
	let result = await getCMSData('event-pages', {
		filters: { $and: andFilterArray },
		populate: ['location', 'image'],
		sort: ['dateTime:asc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const eventPageAction = async (slug, config = {}) => {
	let result = await getCMSData('event-pages', {
		filters: { slug: { $eq: slug } },
		populate: 'deep',
		shapeListResponse: 'true',
	});

	const data = get(result, 'data.data[0]', null);
	if (data) {
		if (config.ignoreRelatedData) {
			removeRelatedData(data, 'DynamicZone');
		} else {
			await populateSectionList(data, 'DynamicZone', true);
		}
	}

	return data;
};

export const getNextUpcomingEventAction = async () => {
	const result = await getCMSData('event-pages', {
		filters: {
			endTime: { $gt: moment().toISOString() },
			showOnListingPage: true,
		},
		pagination: { page: 1, pageSize: 1 },
		populate: [
			'image',
			'paymentDetail',
			'form',
			'form.successModal.successModalButtons',
			'form.errorModal',
			'authUserForm',
			'authUserForm.successModal.successModalButtons',
			'authUserForm.errorModal',
		],
		sort: ['dateTime:asc'],
	});

	return get(result, 'data.data[0].attributes', null);
};
